import _ from 'lodash';
import { IFilter } from '../models/IFilter';
import { IFlight } from '../models/IFlight';
import { IGame } from '../models/IGame';
import { Injectable } from 'angular-ts-decorators';
import { IService } from 'restangular';
import { ITournament } from '../models/ITournament';
import { SortingAlgorithmFactory, SortingType } from './SortingAlgorithmFactory';
import { StandingsService } from './StandingsService';

@Injectable('TournamentsService')
export class TournamentsService {
    static $inject = ['Restangular', 'StandingsService', 'SortingAlgorithmFactory'];
    constructor(private Restangular: IService, private standingsService: StandingsService, private sortingAlgorithmFactory: SortingAlgorithmFactory) {

    }

    getSeasonTournamnets(seasonId: string): ng.IPromise<ITournament[]> {
        let me = this;
        let tournamentsFilter: IFilter = {
            include: [
                'agegroup',
                'teamlevel',
                {
                    flights: [
                        {
                            rounds: {
                                games: [
                                    { date: 'facility' },
                                    { homeTeam: { team: ['positions', 'teamPhoto'] } },
                                    { awayTeam: { team: ['positions', 'teamPhoto'] } }
                                ]
                            }
                        },
                        {
                            teamregistrations: [
                                { team: ['positions', 'teamPhoto'] }
                            ]
                        },
                        {
                            games: [
                                { date: 'facility' },
                                { homeTeam: { team: ['positions', 'teamPhoto'] } },
                                { awayTeam: { team: ['positions', 'teamPhoto'] } }
                            ]
                        }
                    ]
                },
                {
                    championship:
                    {
                        games: [
                            { date: 'facility' },
                            { homeTeam: { team: ['positions', 'teamPhoto'] } },
                            { awayTeam: { team: ['positions', 'teamPhoto'] } }
                        ]
                    }
                }
            ]
        };
        let tournamentsSvc = this.Restangular.one('seasons', seasonId).all('tournaments');
        return tournamentsSvc.getList<ITournament>({ filter: tournamentsFilter })
            .then(function (tournaments) {
                _.each(tournaments, function (tournament) {
                    me.sortData(tournament);
                    me.resolvePromises(tournament);
                });
                return tournaments;
            });
    }

    sortData(tournament: ITournament) {
        let me = this;
        tournament.flights = _.sortBy(tournament.flights, 'flight_number');
        _.each(tournament.flights, function (flight: IFlight) {
            flight.rounds = _.sortBy(flight.rounds, 'roundNumber');
            _.each(flight.rounds, function (round, roundIndex) {
                round.games = _.sortBy(round.games, 'game_number');
            });
        });
        tournament.championship = _.sortBy(tournament.championship, 'roundNumber');
        _.each(tournament.championship, function (round, roundIndex) {
            round.games = _.sortBy(round.games, 'game_number');
        });
    }

    resolvePromises(tournament: ITournament) {
        let me = this;
        _.each(tournament.flights || [], function (flight: IFlight) {
            let allGamesScored = false;
            let gamesWithScores = _.filter(flight.games, function (g: IGame) {
                return ((g.homeTeamScore != null && g.homeTeamScore) >= 0 && (g.awayTeamScore != null && g.awayTeamScore >= 0)) || (g.homeTeamForfeit || g.awayTeamForfeit);
            });
            allGamesScored = gamesWithScores.length >= flight.games.length;
            me.calculateStandings(flight);
            if (allGamesScored) {
                _.each(flight.rounds, function (round, roundIndex) {
                    _.each(round.games, function (game, gameIndex) {
                        if (game.homeTeamPromise && game.homeTeamPromise.promise_type === 'flight') {
                            game.homeTeam = flight.standings[game.homeTeamPromise.seed - 1];
                        }
                        if (game.awayTeamPromise && game.awayTeamPromise.promise_type === 'flight') {
                            game.awayTeam = flight.standings[game.awayTeamPromise.seed - 1];
                        }
                        if (game.homeTeamPromise && game.homeTeamPromise.promise_type === 'bracket_winner') {
                            game.homeTeam = me.standingsService.winningTeam(flight.rounds[roundIndex - 1].games[gameIndex * 2]);
                        }
                        if (game.awayTeamPromise && game.awayTeamPromise.promise_type === 'bracket_winner') {
                            game.awayTeam = me.standingsService.winningTeam(flight.rounds[roundIndex - 1].games[gameIndex * 2 + 1]);
                        }
                        if (game.homeTeamPromise && game.homeTeamPromise.promise_type === 'bracket_champion') {
                            let r = tournament.flights[game.homeTeamPromise.flight_index].rounds;
                            game.homeTeam = me.standingsService.winningTeam(r[r.length - 1].games[0]);
                        }
                        if (game.awayTeamPromise && game.awayTeamPromise.promise_type === 'bracket_champion') {
                            let r = tournament.flights[game.homeTeamPromise.flight_index].rounds;
                            game.awayTeam = me.standingsService.winningTeam(r[r.length - 1].games[0]);
                        }
                    });
                });
            }
        });
        _.each(tournament.championship, function (round, roundIndex) {
            _.each(round.games, function (game, gameIndex) {
                if (game.homeTeamPromise && game.homeTeamPromise.promise_type === 'bracket_winner') {
                    game.homeTeam = me.standingsService.winningTeam(tournament.championship[roundIndex - 1].games[gameIndex * 2]);
                }
                if (game.awayTeamPromise && game.awayTeamPromise.promise_type === 'bracket_winner') {
                    game.awayTeam = me.standingsService.winningTeam(tournament.championship[roundIndex - 1].games[gameIndex * 2 + 1]);
                }
                if (game.homeTeamPromise && game.homeTeamPromise.promise_type === 'bracket_champion') {
                    let r = tournament.flights[game.homeTeamPromise.flight_index].rounds;
                    game.homeTeam = me.standingsService.winningTeam(r[r.length - 1].games[0]);
                }
                if (game.awayTeamPromise && game.awayTeamPromise.promise_type === 'bracket_champion') {
                    let r = tournament.flights[game.homeTeamPromise.flight_index].rounds;
                    game.awayTeam = me.standingsService.winningTeam(r[r.length - 1].games[0]);
                }
            });
        });
    }

    calculateStandings(flight: IFlight) {
        let me = this;
        flight.standings = me.standingsService.calculateStandingsOfTeamRegistrations(flight.teamregistrations);
        flight.standings = me.sortingAlgorithmFactory.createSortingAlgorithm(SortingType.WLHTHPA).sortStandings(flight.standings);
        // _.orderBy(flight.standings, ['wins', 'losses', 'hth', 'pa'], ['desc', 'asc', 'desc', 'asc']);
    }
}
