import { ITeamRegistrationGroup } from "../models/ITeamRegistrationGroup";
import { ITeamPromise } from "../models/ITeamPromise";
import { IGame } from "../models/IGame";
import { IRound } from "../models/IRound";
import { IScheduleConfig } from "../models/IScheduleConfig";
import { ITeamRegistration } from "../models/ITeamRegistration";
import { StandingsService } from "./StandingsService";
import _ from "lodash";
import { Injectable } from "angular-ts-decorators";

@Injectable("SchedulingService")
export class SchedulingService {
  static $inject: string[] = ["StandingsService"];
  constructor(private standingsService: StandingsService) { }

  divisionGames(team: ITeamRegistration) {
    return _.filter(team.homeGames, function (value) {
      return team.divisionId === value.awayTeam.divisionId;
    }).concat(
      _.filter(team.awayGames, function (value) {
        return team.divisionId === value.homeTeam.divisionId;
      })
    );
  }

  outOfDivisionGames(team: ITeamRegistration) {
    return _.filter(team.homeGames, function (value) {
      return team.divisionId !== value.awayTeam.divisionId;
    }).concat(
      _.filter(team.awayGames, function (value) {
        return team.divisionId !== value.homeTeam.divisionId;
      })
    );
  }

  totalGamesTeam(team: ITeamRegistration) {
    return (
      (team.homeGames ? team.homeGames.length : 0) +
      (team.awayGames ? team.awayGames.length : 0)
    );
  }

  totalGamesAll(allTeams: ITeamRegistration[]) {
    let away = _.reduce(
      _.map(allTeams, function (item) {
        return item.awayGames ? item.awayGames.length : 0;
      }),
      function (sum: number, n: number) {
        return sum + n;
      }
    );
    let home = _.reduce(
      _.map(allTeams, function (item) {
        return item.homeGames ? item.homeGames.length : 0;
      }),
      function (sum: number, n: number) {
        return sum + n;
      }
    );
    return away + home;
  }

  buildSchedule(divisions: ITeamRegistrationGroup[], config: IScheduleConfig) {
    if (config.attempts >= config.maxAttempts) config.attempts = 0;
    config.attempts++;
    console.info("building schedule ", config.attempts);
    _.each(divisions, function (division) {
      _.each(division.teamregistrations, function (registration) {
        _.remove(registration.awayGames, function (game) {
          return game.id == null;
        });
        _.remove(registration.homeGames, function (game) {
          return game.id == null;
        });
      });
    });

    this.createDivisionGames(divisions, config);
    if (config.useDivisionTeams || config.useOutOfDivisionTeams) {
      this.createAllGames(divisions, config);
    }
  }

  buildScheduleNew(
    divisions: ITeamRegistrationGroup[],
    config: IScheduleConfig
  ): void {
    if (config.attempts >= config.maxAttempts) config.attempts = 0;
    config.attempts++;
    console.info("building schedule ", config.attempts);
    _.each(divisions, (division) => {
      _.each(division.teamregistrations, (registration) => {
        _.remove(registration.awayGames, (game) => {
          return game.id == null;
        });
        _.remove(registration.homeGames, (game) => {
          return game.id == null;
        });
      });
    });

    _.each(divisions, (division: ITeamRegistrationGroup) => {
      let teams = _.clone(division.teamregistrations);
      if (teams.length <= 0) return;
      teams = _.shuffle(teams);
      this.buildDivisionSchedule(teams, config);
    });
    if (config.useOutOfDivisionTeams) {
      this.buildOutOfDivisionSchedule(divisions, config);
      for (let division of divisions) {
        for (let team of division.teamregistrations) {
          if (team.homeGames.length + team.awayGames.length < config.maxGames) {
            if (config.attempts < 500) {
              this.buildScheduleNew(divisions, config);
            }
          }
        }
      }
    }
  }

  buildOutOfDivisionSchedule(
    divisions: ITeamRegistrationGroup[],
    config: IScheduleConfig
  ): void {
    let gamesEach = config.maxGames / 2;
    let teams: ITeamRegistration[] = [];
    _.each(divisions, (division) => {
      teams.push(...division.teamregistrations);
    });

    let matchMatrix = new Array(teams.length)
      .fill(0)
      .map(() => new Array(teams.length).fill(0));

    teams = _.shuffle(teams);
    let rounds = config.maxOutOfDivisionGames + teams.length + 1;
    for (let x = 0; x < rounds; x++) {
      for (let j = 0; j < teams.length; j++) {
        for (let i = 0; i < teams.length; i++) {
          let homeTeam = i;
          let awayTeam = (i + j) % teams.length;
          if (homeTeam === awayTeam) {
            continue;
          }
          let ht = teams[homeTeam];
          let at = teams[awayTeam];
          if (ht.divisionId == at.divisionId) {
            continue;
          }
          if (
            ht.homeGames.length >= gamesEach ||
            at.awayGames.length >= gamesEach
          ) {
            continue;
          }
          if (
            matchMatrix[homeTeam][awayTeam] + matchMatrix[awayTeam][homeTeam] >=
            config.maxOutOfDivisionGames
          ) {
            //console.log("skipping", ht.team.name, at.team.name, matchMatrix[homeTeam][awayTeam], matchMatrix[awayTeam][homeTeam]);
            continue;
          }
          matchMatrix[homeTeam][awayTeam] += 1;
          let gm = {
            homeTeam: teams[homeTeam],
            awayTeam: teams[awayTeam],
          } as IGame;
          teams[homeTeam].homeGames.push(gm);
          teams[awayTeam].awayGames.push(gm);
        }
      }
    }

    //console.log(matchMatrix);
  }

  buildDivisionSchedule(teams: ITeamRegistration[], config: IScheduleConfig) {
    let matchMatrix = new Array(teams.length)
      .fill(0)
      .map(() => new Array(teams.length).fill(0));
    let gamesEachRule = (teams.length - 1) * config.gamesEach;
    let maxGamesRule = Math.ceil(config.maxGames / 2);
    let homeGames =
      config.gamesEach > 0
        ? Math.min(gamesEachRule, maxGamesRule)
        : maxGamesRule;
    let unmatchFactor = teams.length / (teams.length - 1);
    let rounds = Math.ceil(homeGames * unmatchFactor); // Math.max(1, Math.floor(config.maxGames / (teams.length - 1)));
    for (let j = 1; j < rounds; j++) {
      for (let i = 0; i < teams.length; i++) {
        let homeTeam = i;
        let awayTeam = (i + j) % teams.length;
        if (homeTeam === awayTeam) {
          continue;
        }
        matchMatrix[homeTeam][awayTeam] += 1;
        let gm = {
          homeTeam: teams[homeTeam],
          awayTeam: teams[awayTeam],
        } as IGame;
        teams[homeTeam].homeGames.push(gm);
        teams[awayTeam].awayGames.push(gm);
      }
    }
    //console.log("division games", matchMatrix);
  }

  byeTeams: ITeamRegistration[] = [];
  mapRound(roundNumber: number, teams: ITeamRegistration[]) {
    let games: IGame[] = [];
    for (let i = 0; i < teams.length / 2; i++) {
      let hti = roundNumber % 2 ? i : teams.length - i - 1;
      let ati = roundNumber % 2 ? teams.length - i - 1 : i;
      if (teams[hti].byeTeam || teams[ati].byeTeam) {
        this.byeTeams.push(teams[hti].byeTeam ? teams[ati] : teams[hti]);
        continue;
      }
      let gm = { homeTeam: teams[hti], awayTeam: teams[ati] } as IGame;
      teams[hti].homeGames.push(gm);
      teams[ati].awayGames.push(gm);
      games.push(gm);
    }
    return games;
  }

  createRoundRobin(teamregistrations: ITeamRegistration[]) {
    let games: IGame[] = [];
    let teams = _.clone(teamregistrations);
    teams = _.shuffle(teams);
    for (let i = 0; i < teams.length; i++) {
      for (let j = i + 1; j < teams.length; j++) {
        console.log(`i=${i} j=${j}`);
        let homeGame = (i + j) % 2;
        let homeTeamIdx = homeGame ? i : j;
        let awayTeamIdx = homeGame ? j : i; console.log(`[${homeTeamIdx},${awayTeamIdx}]`);
        let gm = {
          homeTeam: teams[homeTeamIdx],
          awayTeam: teams[awayTeamIdx]
        } as IGame;
        teams[homeTeamIdx].homeGames.push(gm);
        teams[awayTeamIdx].awayGames.push(gm);
        games.push(gm);
      }
    }
    return games;
  }

  createNumberOfGames(
    teamregistrations: ITeamRegistration[],
    totalGames: number
  ) {
    let games: IGame[] = [];
    let teams = _.clone(teamregistrations);
    for (let j = 1; j < 1 + (totalGames / 2); j++) {
      for (let i = 0; i < teams.length; i++) {
        let homeTeam = i;
        let awayTeam = (i + j) % teams.length;
        if (homeTeam === awayTeam) {
          continue;
        }
        let gm = {
          homeTeam: teams[homeTeam],
          awayTeam: teams[awayTeam],
        } as IGame;
        teams[homeTeam].homeGames.push(gm);
        teams[awayTeam].awayGames.push(gm);
        games.push(gm);
      }
    }
    return games;
  }

  createDivisionGames(
    divisions: ITeamRegistrationGroup[],
    config: IScheduleConfig
  ) {
    let me = this;
    _.each(divisions, function (division: ITeamRegistrationGroup) {
      let teams = _.clone(division.teamregistrations);
      if (teams.length % 2)
        teams.unshift({ byeTeam: true, team: { name: "bye team" } } as any);
      let games = (teams.length - 1) * config.gamesEach;
      for (let r = 0; r < games; r++) {
        me.mapRound(r, teams);
        me.shift(teams);
      }
      let totalGamesCreated =
        (division.teamregistrations.length - 1) * config.gamesEach;
      if (totalGamesCreated > config.maxGames) {
        let homeGamesToTrim = (totalGamesCreated - config.maxGames) / 2;
        let ignoreOneGame =
          division.teamregistrations.length - 1 > config.maxGames;
        for (let index = 0; index < homeGamesToTrim; index++) {
          let maxHomeGames = totalGamesCreated / 2 - (index + 1);
          let maxAwayGames = totalGamesCreated / 2 - (index + 1);
          let teamPool = _.shuffle(division.teamregistrations);
          _.each(teamPool, function (tr: ITeamRegistration) {
            let allTeams = _.map(tr.homeGames, function (hg) {
              return hg.awayTeam.id;
            }).concat(
              _.map(tr.awayGames, function (ag) {
                return ag.homeTeam.id;
              })
            );
            let countedGames = _.countBy(allTeams.sort(), (t) => {
              return t;
            });

            let homeGamesSorted = _.orderBy(
              tr.homeGames,
              (hg) => {
                hg.totalPlayed = countedGames[hg.awayTeam.id];
                return countedGames[hg.awayTeam.id];
              },
              "desc"
            );
            let game = _.find(homeGamesSorted, (hgs) => {
              return (
                hgs.awayTeam.awayGames.length > maxAwayGames &&
                (ignoreOneGame || hgs.totalPlayed > 1)
              );
            });
            if (game) {
              let hti = game.homeTeam.homeGames.indexOf(game);
              let ati = game.awayTeam.awayGames.indexOf(game);
              game.homeTeam.homeGames.splice(hti, 1);
              game.awayTeam.awayGames.splice(ati, 1);
            }
          });
        }
      }
    });
    let tooMany = false;
    _.each(divisions, function (division: ITeamRegistrationGroup) {
      _.each(division.teamregistrations, function (tr: ITeamRegistration) {
        if (me.divisionGames(tr).length > config.maxGames) {
          tooMany = true;
        }
      });
    });
    if (tooMany && config.attempts < config.maxAttempts) {
      me.buildSchedule(divisions, config);
    }
  }

  shift(teams: ITeamRegistration[]) {
    let movedTeam = teams.splice(1, 1);
    teams.push(movedTeam[0]);
  }

  createAllGames(divisions: ITeamRegistrationGroup[], config: IScheduleConfig) {
    let me = this;
    let rounds = [];
    let allTeams: ITeamRegistration[] = [];

    for (let division of divisions) {
      allTeams = allTeams.concat(division.teamregistrations);
    }
    let totalTeams = allTeams.length;
    let shuffledTeams = _.shuffle(allTeams);

    let attempts = 0;
    while (this.totalGamesAll(shuffledTeams) < config.maxGames * totalTeams) {
      let doBreak = false;
      _.each(shuffledTeams, function (team) {
        if (team.byeTeam) return; // ignore the bye team
        if (me.totalGamesTeam(team) >= config.maxGames) return;
        // find an out of division team

        if (
          config.useOutOfDivisionTeams &&
          team.homeGames.length < config.maxGames / 2
        ) {
          let oodTeamsIHaveNotPlayedEnough = _.filter(
            shuffledTeams,
            function (t: ITeamRegistration) {
              let timesPlayed = _.filter(
                team.homeGames.concat(team.awayGames),
                function (g) {
                  return (
                    (g.homeTeam.id === team.id && g.awayTeam.id === t.id) ||
                    (g.homeTeam.id === t.id && g.awayTeam.id === team.id)
                  );
                }
              );
              // console.log("times played this opponent : ", timesPlayed.length, t && t.team && t.team.name);
              let outOfDivisionCheck =
                t.divisionId !== team.divisionId && // it's on ood team
                t.awayGames.length < config.maxGames / 2 && // I have away games to fill
                timesPlayed.length < config.maxOutOfDivisionGames;
              return outOfDivisionCheck;
            }
          );
          if (
            oodTeamsIHaveNotPlayedEnough &&
            oodTeamsIHaveNotPlayedEnough.length
          ) {
            let opponent = _.sample(oodTeamsIHaveNotPlayedEnough);
            let game: IGame = { homeTeam: team, awayTeam: opponent } as IGame;
            team.homeGames.push(game);
            opponent.awayGames.push(game);
          } else {
            attempts++;
          }
        }
      });
      if (attempts > 20) break;
    }

    let tooFiew = false;
    _.each(divisions, function (division: ITeamRegistrationGroup) {
      _.each(division.teamregistrations, function (tr: ITeamRegistration) {
        if (me.totalGamesTeam(tr) < config.maxGames) {
          tooFiew = true;
        }
      });
    });
    if (tooFiew && config.attempts < config.maxAttempts) {
      me.buildSchedule(divisions, config);
    }
    return;
  }

  /**
   * Bracket logic below
   */
  buildMatches(r: number, n: number): number[] {
    let m = [1]; // initial team if only one
    for (let i = 1; i <= r; i++) {
      let l = 2 * m.length + 1;
      let tmp = [];
      _.each(m, function (item) {
        tmp.push(item);
        tmp.push(l - item <= n ? l - item : 0);
      });
      m = tmp;
    }
    return m;
  }
  /**
   * Main logic to build the single eliminiation tournament schedule.
   * Probably refactor this out into a tournament service
   */
  buildBracket(n: number, teams: ITeamPromise[]) {
    if (n <= 0 || !teams || teams.length <= 0) return;
    let me = this;
    let r = Math.ceil(Math.log(n) / Math.log(2));
    let m = me.buildMatches(r, n);
    let byeTeam = { byeTeam: true } as ITeamRegistration;
    let matches: IGame[] = [];
    let rounds: IRound[] = [];
    rounds[0] = { games: [], roundNumber: 1 } as IRound;
    let gn: number = 1;
    for (let i = 0; i < m.length; i += 2) {
      let ht = teams[m[i] - 1];
      let at = m[i + 1] - 1 >= 0 ? teams[m[i + 1] - 1] : byeTeam;
      let g = {
        game_number: gn,
      } as IGame;
      if (ht.promise_name) {
        g.homeTeamPromise = ht;
      } else {
        g.homeTeam = ht as ITeamRegistration;
      }
      if (at.promise_name) {
        g.awayTeamPromise = at;
      } else {
        g.awayTeam = at as ITeamRegistration;
      }
      rounds[0].games.push(g);
      matches.push(g);
      gn++;
    }

    for (let cr = 2; cr <= r; cr++) {
      let currentRound = cr - 1;
      let previousRound = cr - 2;
      rounds[currentRound] = { games: [], roundNumber: cr } as IRound;
      let pr: IRound = rounds[previousRound];
      gn = 1;
      for (let i = 0; i < pr.games.length; i += 2) {
        let pg1 = pr.games[i];
        let pg2 = pr.games[i + 1];
        let ht: ITeamRegistration = me.standingsService.winningTeam(pg1);
        let at: ITeamRegistration = me.standingsService.winningTeam(pg2);
        let g = { game_number: gn, homeTeam: ht, awayTeam: at } as IGame;
        if (ht == null) {
          if (pg1.homeTeamPromise && pg1.awayTeam && pg1.awayTeam.byeTeam) {
            g.homeTeamPromise = pg1.homeTeamPromise;
          } else {
            g.homeTeamPromise = {
              awayGames: [],
              homeGames: [],
              promise_name: "previous match winner",
              promise_type: "bracket_winner",
              gameId: "",
            } as ITeamRegistration;
          }
        }
        if (at == null) {
          if (pg2.homeTeamPromise && pg2.awayTeam && pg2.awayTeam.byeTeam) {
            g.awayTeamPromise = pg2.homeTeamPromise;
          } else {
            g.awayTeamPromise = {
              awayGames: [],
              homeGames: [],
              promise_name: "previous match winner",
              promise_type: "bracket_winner",
              gameId: "",
            } as ITeamRegistration;
          }
        }
        rounds[currentRound].games.push(g);
        matches.push(g);
        gn++;
      }
    }
    return rounds;
  }
}
