import {
  StateDeclaration,
  StateProvider,
  Transition,
  UrlService,
} from "@uirouter/angularjs";
import { VanityUrlAdd } from "./components/admin/vanityurl/add/component";
import { VanityUrl } from "./components/admin/vanityurl/component";
import { SiteNotifications } from "./components/notifications/component";
import { Suggestions } from "./components/suggestions";
import { CreateRoundRobin } from "./components/team/roundrobin/create";
import { HostRoundRobin } from "./components/team/roundrobin/host";
import { RoundRobinInvitations } from "./components/team/roundrobin/invitations";
import { ViewRoundRobin } from "./components/team/roundrobin/view";
import { AdminController } from "./controllers/AdminController";
import { AgeGroupController } from "./controllers/AgeGroupController";
import { AssignDivisionsController } from "./controllers/AssignDivisionsController";
import { CarouselController } from "./controllers/CarouselController";
import { DivisionsController } from "./controllers/DivisionsController";
import { FacilityController } from "./controllers/FacilityController";
import { GameManagerController } from "./controllers/GameManagerController";
import { GamesReportController } from "./controllers/GamesReportController";
import { LeagueController } from "./controllers/LeagueController";
import { OfficialsController } from "./controllers/OfficialsController";
import { OrganizationAdminController } from "./controllers/OrganizationAdminController";
import { PageController } from "./controllers/PageController";
import { PagesController } from "./controllers/PagesController";
import { PermissionsController } from "./controllers/PermissionsController";
import { RegistrationDashboardController } from "./controllers/RegistrationDashboardController";
import { RosterExportController } from "./controllers/RosterExportController";
import { RosterReportController } from "./controllers/RosterReportController";
import { ScheduleController } from "./controllers/ScheduleController";
import { SeasonScheduleController } from "./controllers/SeasonScheduleController";
import { SeasonsController } from "./controllers/SeasonsController";
import { SponsorsController } from "./controllers/SponsorsController";
import { SportsController } from "./controllers/SportsController";
import { TeamController } from "./controllers/TeamController";
import { TeamHomeController } from "./controllers/TeamHomeController";
import { TeamLevelController } from "./controllers/TeamLevelController";
import { TeamRegistrationController } from "./controllers/TeamRegistrationController";
import { TournamentAdminController } from "./controllers/TournamentAdminController";
import { ILeague } from "./models/ILeague";
import { ISeason } from "./models/ISeason";
import { IUser } from "./models/IUser";
import { PlanManager } from "./modules/practiceplans/planmanager/planmanager";
import { LeagueService } from "./services/LeagueService";
import { SeasonService } from "./services/SeasonService";
import { TeamService } from "./services/TeamService";
import { UserService } from "./services/UserService";
import { CommonVanityUrlService } from "./services/VanityUrlService";
/**
 * Class to configure the various status of the application
 *
 * @export
 * @class States
 */
export class States {
  public static Config(
    $stateProvider: StateProvider,
    $urlServiceProvider: UrlService
  ) {
    $stateProvider
      .state({
        name: "league",
        url: "/league?{seasonOverride:string}",
        abstract: true,
        template: require("./templates/league/template.html").default,
        controller: LeagueController,
        controllerAs: "league",
        resolve: {
          leagueId: [
            "CommonVanityUrlService",
            (vanityUrlService: CommonVanityUrlService) => {
              return vanityUrlService.resolve().then((data) => data?.id);
            },
          ],
          resource: [
            "LeagueService",
            "leagueId",
            (leagueService: LeagueService, leagueId: string) => {
              return leagueService.get(leagueId).catch((error) => {
                return null;
              });
            },
          ],
          user: [
            "UserService",
            (userService: UserService) => {
              return userService.me().catch((error) => {
                return null;
              });
            },
          ],
          season: [
            "LeagueService",
            "SeasonService",
            "leagueId",
            "$transition$",
            (
              leagueService: LeagueService,
              seasonService: SeasonService,
              leagueId: string,
              $transition: Transition
            ) => {
              if ($transition.params().seasonOverride) {
                return seasonService.get($transition.params().seasonOverride);
              } else {
                return leagueService.getCurrentSeason({
                  id: leagueId,
                } as ILeague);
              }
            },
          ],
        },
      })
      .state({
        name: "league.admin",
        url: "/admin",
        template: require("./templates/admin/template.html").default,
        controller: AdminController,
        controllerAs: "admin",
        data: {
          pageTitle: "admin",
        },
      })
      .state({
        name: "league.suggestions",
        url: "/suggestions",
        component: Suggestions.Name,
        resolve: {
          user: ["user", (user: IUser) => user],
          league: ["resource", (resource: ILeague) => resource],
        },
      })
      .state({
        name: "league.admin.users",
        abstract: true,
        url: "/users",
        template: require("./templates/admin/users.html").default,
        data: {
          pageTitle: "user admin",
        },
      })
      .state({
        name: "league.admin.users.list",
        url: "",
        template: require("./templates/admin/users.list.html").default,
        data: {
          pageTitle: "user list",
        },
      })
      .state({
        name: "league.admin.users.edit",
        url: "/:userId/edit",
        template: require("./templates/admin/users.edit.html").default,
        data: {
          pageTitle: "user edit",
        },
      })
      .state({
        name: "league.admin.users.roles",
        url: "/:userId/roles",
        template: require("./templates/admin/users.roles.html").default,
        data: {
          pageTitle: "user roles",
        },
      })
      .state({
        name: "league.admin.permissions",
        url: "/permissions",
        template: require("./templates/admin/permissions.html").default,
        controller: PermissionsController,
        controllerAs: "permissions",
        data: {
          pageTitle: "user permissions",
        },
      })
      .state({
        name: "league.admin.permissions.add",
        url: "/add",
        template: require("./templates/admin/permissions.add.html").default,
        data: {
          pageTitle: "user add permissions",
        },
      })
      .state({
        name: "league.admin.permissions.edit",
        url: "/:aclId",
        template: require("./templates/admin/permissions.edit.html").default,
        data: {
          pageTitle: "user edit permissions",
        },
      })
      .state({
        name: "league.admin.migrate",
        url: "/migrate",
        template: require("./templates/admin/migrate.html").default,
        data: {
          pageTitle: "migrate teams",
        },
      })
      .state({
        name: "league.admin.gamemanager",
        url: "/games",
        template: require("./templates/admin/game.manager.html").default,
        controller: GameManagerController,
        controllerAs: "gamemanager",
      })
      .state({
        name: "league.admin.vanityurls",
        url: "/urls",
        component: VanityUrl.Name,
        resolve: {
          urls: [
            "VanityUrlService",
            (vanityUrlService: VanityUrlService) => {
              return vanityUrlService.getUrls();
            },
          ],
        },
      })
      .state({
        name: "league.admin.vanityurls.add",
        url: "/add",
        component: VanityUrlAdd.Name,
      })
      .state({
        name: "league.admin.planmanager",
        url: "/planmanager",
        component: PlanManager.Name,
      })
      .state({
        name: "league.carousels",
        url: "/carousels",
        template: require("./templates/league/carousel.manager.html").default,
        controller: CarouselController,
        controllerAs: "carousels",
        data: {
          pageTitle: "slideshow manager",
        },
      })
      .state({
        name: "league.notifications",
        url: "/notifications",
        component: SiteNotifications.Name,
      })
      .state({
        name: "league.pages",
        url: "/pages",
        template: require("./templates/league/pages.manager.html").default,
        controller: PagesController,
        controllerAs: "pages",
        data: {
          pageTitle: "page manager",
        },
      })
      .state({
        name: "league.page",
        url: "/pages/:pageId",
        template: require("./templates/league/page.html").default,
        controller: PageController,
        controllerAs: "page",
      })
      .state({
        name: "league.sponsors",
        url: "/sponsors",
        template: require("./templates/league/sponsor.manager.html").default,
        controller: SponsorsController,
        controllerAs: "sponsors",
        data: {
          pageTitle: "sponsor manager",
        },
      })
      .state({
        name: "league.home",
        url: "",
        // component: LeagueHomeComponent.Name,
        template: require("./templates/league/home.html").default,
        data: {
          pageTitle: "home",
        },
      })
      .state({
        name: "league.home.addmessage",
        url: "/addmessage",

        template: require("./templates/league/messages.add.html").default,
      })
      .state({
        name: "league.home.editmessage",
        url: "/editmessage",
        template: require("./templates/league/messages.edit.html").default,
      })
      .state({
        name: "league.profile",
        url: "/profile",
        template: require("./templates/league/profile.html").default,
      })
      .state({
        name: "league.registeruser",
        url: "/registeruser",
        template: require("./templates/league/register.html").default,
      })
      .state({
        name: "league.verify",
        url: "/verify",
        template: require("./templates/league/verify.html").default,
      })
      .state({
        name: "league.contacts",
        url: "/contacts",
        template: require("./templates/league/contacts.html").default,
      })
      .state({
        name: "league.settings",
        url: "/settings",
        template: require("./templates/league/settings.html").default,
      })
      .state({
        name: "league.messages",
        url: "/messages",
        template: require("./templates/league/messages.html").default,
      })
      .state({
        name: "league.messages.add",
        url: "/add",
        template: require("./templates/league/messages.add.html").default,
      })
      .state({
        name: "league.messages.edit",
        url: "/:id",
        template: require("./templates/league/messages.edit.html").default,
      })
      .state({
        name: "league.facility",
        url: "/facilities",
        template: require("./templates/league/facilities.html").default,
        controller: FacilityController,
        controllerAs: "facility",
      })
      .state({
        name: "league.schedule",
        url: "/schedule",
        template: require("./templates/schedule/schedule.html").default,
        controller: ScheduleController,
        controllerAs: "schedule",
      })
      .state({
        name: "league.tournamentadmin",
        url: "/tournamentadmin",
        template: require("./templates/tournament/admin.html").default,
        controller: TournamentAdminController,
        controllerAs: "tournamentAdmin",
      })
      .state({
        name: "league.tournamentadmin.add",
        url: "/add",
        template: require("./templates/tournament/add.html").default,
      })
      .state({
        name: "league.tournamentadmin.edit",
        url: "/:tournamentId/edit",
        template: require("./templates/tournament/edit.html").default,
      })
      .state({
        name: "league.tournament",
        url: "/tournament",
        template: require("./templates/tournament/display.html").default,
        controller: TournamentAdminController,
        controllerAs: "tourney",
      })
      .state({
        name: "league.tournament.run",
        url: "/:tournamentId",
        template: require("./templates/tournament/run.html").default,
      })
      .state({
        name: "league.divisions",
        url: "/divisions",
        template: require("./templates/divisions.html").default,
        controller: DivisionsController,
        controllerAs: "divisions",
      })
      .state({
        name: "league.seasons",
        url: "/seasons",
        template: require("./templates/seasons.html").default,
        controller: SeasonsController,
        controllerAs: "seasons",
      })
      .state({
        name: "league.seasons.create",
        url: "/create",
        template: require("./templates/season.create.html").default,
      })
      .state({
        name: "league.seasons.edit",
        url: "/:seasonId",
        template: require("./templates/season.edit.html").default,
      })

      .state({
        name: "league.standings",
        url: "/standings",
        template: require("./templates/league/standings.html").default,
      })

      .state({
        name: "league.team",
        abstract: true,
        url: "/team/:urlKey",
        template: require("./templates/team/template.html").default,
        controller: TeamHomeController,
        controllerAs: "team",
        data: {
          pageTitle: "home",
        },
        resolve: {
          registration: [
            "season",
            "TeamService",
            "$transition$",
            (
              season: ISeason,
              teamService: TeamService,
              $transition$: Transition
            ) => {
              return teamService.getTeamByKey(
                season,
                $transition$.params().urlKey
              );
            },
          ],
        },
      })
      .state({
        name: "league.team.home",
        url: "",
        template: require("./templates/team/home.html").default,
      })
      .state({
        name: "league.team.edit",
        url: "/edit",
        template:
          require("./components/team/editor/TeamEditor.home.page.template.html")
            .default,
      })
      .state({
        name: "league.team.rostermanager",
        url: "/rostermanager",
        template: require("./templates/team/roster.html").default,
      })
      .state({
        name: "league.team.scheduling",
        url: "/scheduling",
        template: require("./templates/team/scheduling.html").default,
      })
      .state({
        name: "league.team.roundrobins",
        url: "/roundrobins",
        template: require("./templates/team/roundrobins.html").default,
        abstract: true,
      })
      .state({
        name: "league.team.roundrobins.join",
        url: "",
        component: RoundRobinInvitations.Name,
      })
      .state({
        name: "league.team.roundrobins.host",
        url: "/host",
        component: HostRoundRobin.Name,
      })
      .state({
        name: "league.team.roundrobins.host.create",
        url: "/create",
        component: CreateRoundRobin.Name,
      })
      .state({
        name: "league.team.roundrobins.host.view",
        url: "/view/:id",
        component: ViewRoundRobin.Name,
      })

      .state({
        name: "league.team.rostermanager.add",
        url: "/add",
        template: require("./templates/team/roster.add.html").default,
      })

      .state({
        name: "league.team.rostermanager.edit",
        url: "/edit",
        template: require("./templates/team/roster.edit.html").default,
      })

      .state({
        name: "league.team.rostermanager.upload",
        url: "/upload",
        template: require("./templates/team/roster.upload.html").default,
      })

      .state({
        name: "league.team.homefield",
        url: "/homefield",
        template: require("./templates/team/homefield.html").default,
      })

      .state({
        name: "league.team.homedates",
        url: "/homedates",
        template: require("./templates/team/homedates.html").default,
      })

      .state({
        name: "league.team.homedates.add",
        url: "/add",
        template: require("./templates/team/homedates.add.html").default,
      })

      .state({
        name: "league.team.homedates.edit",
        url: "/edit",
        template: require("./templates/team/homedates.edit.html").default,
      })

      .state({
        name: "league.team.game",
        url: "/game/:gameId",
        abstract: true,
        template: require("./templates/team/game.html").default,
      })

      .state({
        name: "league.team.game.info",
        url: "",
        template: require("./templates/team/game.info.html").default,
      })

      .state({
        name: "league.team.game.choosedate",
        url: "/choosedate",
        template: require("./templates/team/game.choosedate.html").default,
      })

      .state({
        name: "league.team.game.enterscore",
        url: "/enterscore",
        template: require("./templates/team/game.enterscore.html").default,
      })

      .state({
        name: "league.team.game.changelocation",
        url: "/changelocation",
        template: require("./templates/team/game.changelocation.html").default,
      })

      .state({
        name: "league.team.messages",
        url: "/messages",
        template: require("./templates/team/messages.html").default,
      })

      .state({
        name: "league.team.messages.add",
        url: "/add",
        template: require("./templates/team/messages.add.html").default,
      })

      .state({
        name: "league.team.messages.edit",
        url: "/:messageId",
        template: require("./templates/team/messages.edit.html").default,
      })

      .state({
        name: "league.team.registrationform",
        url: "/registrationform",
        template: require("./templates/team/registrationform.html").default,
      })
      .state({
        name: "league.team.payment",
        url: "/payment/:sessionId",
        template: require("./templates/team/paymentresponse.html").default,
      })
      .state({
        name: "league.team.staff",
        url: "/staff",
        template: require("./templates/team/staff.html").default,
      })

      .state({
        name: "league.team.staff.add",
        url: "/add",
        template: require("./templates/team/staff.add.html").default,
      })

      .state({
        name: "league.team.staff.edit",
        url: "/edit",
        template: require("./templates/team/staff.edit.html").default,
      })
      .state({
        name: "league.team.headcoach",
        url: "/headcoach",
        template: require("./templates/team/headcoach.html").default,
      })
      .state({
        name: "league.assigndivisions",
        url: "/assigndivisions",
        template: require("./templates/assigndivisions.html").default,
        controller: AssignDivisionsController,
        controllerAs: "assigndivisions",
      })
      .state({
        name: "league.help",
        url: "/help",
        template: require("./templates/league/help.html").default,
      })
      .state({
        name: "league.teams",
        url: "/teams",
        template: require("./templates/teams.html").default,
        controller: TeamController,
        controllerAs: "teams",
      })
      .state({
        name: "league.teams.create",
        url: "/create",
        template: require("./templates/teams.create.html").default,
      })
      .state({
        name: "league.teams.edit",
        url: "/:teamId",
        template: require("./templates/teams.edit.html").default,
      })
      .state({
        name: "league.teamRegistrations",
        url: "/teamRegistrations",
        template: require("./templates/teamRegistrations.html").default,
        controller: TeamRegistrationController,
        controllerAs: "teamRegistrations",
      })
      .state({
        name: "league.teamRegistrations.create",
        url: "/create",
        template: require("./templates/teamRegistrations.create.html").default,
      })
      .state({
        name: "league.teamRegistratileaons.edit",
        url: "/:teamId",
        template: require("./templates/teamRegistrations.edit.html").default,
      })
      .state({
        name: "league.seasonSchedule",
        url: "/seasonschedule",
        template: require("./templates/league/schedule.html").default,
        controller: SeasonScheduleController,
        controllerAs: "seasonSchedule",
      })
      /*.state({
        name: "league.register",
        url: "/register",
        template: require("./templates/register.html").default,
        controller: RegistrationController,
        controllerAs: "registrations"
      })*/
      .state({
        name: "league.ageGroups",
        url: "/ageGroups",
        template: require("./templates/ageGroups.html").default,
        controller: AgeGroupController,
        controllerAs: "ageGroups",
      })
      .state({
        name: "league.ageGroups.create",
        url: "/create",
        template: require("./templates/ageGroups.create.html").default,
      })
      .state({
        name: "league.ageGroups.edit",
        url: "/:ageGroupId",
        template: require("./templates/ageGroups.edit.html").default,
      })
      .state({
        name: "league.teamLevels",
        url: "/teamLevels",
        template: require("./templates/teamLevels.html").default,
        controller: TeamLevelController,
        controllerAs: "teamLevels",
      })
      .state({
        name: "league.reports",
        url: "/reports",
        template: require("./templates/reports/template.html").default,
      })
      .state({
        name: "league.reports.roster",
        url: "/roster",
        template: require("./templates/reports/roster.html").default,
        controller: RosterReportController,
        controllerAs: "rosterreport",
      })
      .state({
        name: "league.reports.roster-export",
        url: "/export",
        template: require("./templates/reports/roster.export.html").default,
        controller: RosterExportController,
        controllerAs: "rosterexport",
      })
      .state({
        name: "league.reports.registrationdashboard",
        url: "/registrationdashboard",
        template: require("./templates/reports/registrationdashboard.html")
          .default,
        controller: RegistrationDashboardController,
        controllerAs: "dashboard",
      })
      .state({
        name: "league.reports.registrationcleanup",
        url: "/registrationcleanup",
        template: require("./templates/reports/registrationcleanup.html")
          .default,
        controller: RegistrationDashboardController,
        controllerAs: "dashboard",
      })
      .state({
        name: "league.reports.games",
        url: "/games",
        template: require("./templates/reports/games.report.html").default,
        controller: GamesReportController,
        controllerAs: "gamesreport",
      })
      .state({
        name: "league.reports.standings",
        url: "/standings",
        template: require("./templates/reports/standings.html").default,
      })
      .state({
        name: "league.officials",
        url: "/officials",
        template: require("./templates/league/officials.html").default,
        controller: OfficialsController,
        controllerAs: "officials",
      })

      .state({
        name: "admin",
        url: "/admin",
        abstract: true,
        template: require("./templates/globaladmin/template.html").default,
      })
      .state({
        name: "admin.sports",
        url: "/sports",
        template: require("./templates/globaladmin/sports.html").default,
        controller: SportsController,
        controllerAs: "$ctrl",
      })
      .state({
        name: "admin.organizations",
        url: "/organizations",
        template: require("./templates/globaladmin/organizations.html").default,
        controller: OrganizationAdminController,
        controllerAs: "$ctrl",
      });

    $urlServiceProvider.rules.otherwise("/league");
  }
}
