import { UIComponent } from "../../../core/UIComponent";
import { Component, Input } from "angular-ts-decorators";
import template from "./contacts.html";
import { ISeason } from "../../../models/ISeason";
import { IAgeGroup } from "../../../models/IAgeGroup";
import { SeasonService } from "../../../services/SeasonService";
import { ITeamRegistrationGroup } from "../../../models/ITeamRegistrationGroup";
import _ from "lodash";
import { ITeamRegistration } from "../../../models/ITeamRegistration";

const name: string = "contacts";

@Component({
  selector: name,
  template,
})
export class LeagueContactsComponent extends UIComponent {
  @Input() season: ISeason;
  @Input() agegroups: IAgeGroup[];

  static Name: string = name;
  static $inject: string[] = ["SeasonService", "$sce"];

  private ageGroup: IAgeGroup;

  private teams: ITeamRegistration[];

  private teamReport: any;

  public csvContent: string;

  constructor(
    public seasonService: SeasonService,
    public $sce: angular.ISCEService
  ) {
    super();
  }

  $onInit() {}

  selectAgeGroup(ageGroup: IAgeGroup) {
    this.ageGroup = ageGroup;
    this.seasonService.getTeams(this.season, this.ageGroup).then((teams) => {
      this.teams = teams;
      this.buildReport();
    });
  }

  buildReport() {
    this.teamReport = [];
    _.each(this.teams, (registration: ITeamRegistration) => {
      if (!registration || !registration.team) return;
      let reportRow = {
        team: registration.team.name || "",
        agegroup: registration.ageGroup.name || "",
        level: registration.teamLevel.name || "",
        type: "manager",
        firstName:
          (registration.team.manager && registration.team.manager.firstName) ||
          "",
        lastName:
          (registration.team.manager && registration.team.manager.lastName) ||
          "",
        email:
          (registration.team.manager && registration.team.manager.email) || "",
        phone:
          (registration.team.manager && registration.team.manager.cellPhone) ||
          "",
        homePhone:
          (registration.team.manager && registration.team.manager.homePhone) ||
          "",
      };
      this.teamReport.push(reportRow);

      _.each(registration.team.positions, (position) => {
        if (!position || !position.user) return;
        try {
          let positionReportRow = {
            team: registration.team.name || "",
            agegroup: registration.ageGroup.name || "",
            level: registration.teamLevel.name || "",
            type: position.positionType || "",
            firstName: position.user.firstName || "",
            lastName: position.user.lastName || "",
            email: position.user.email || "",
            phone: position.user.cellPhone || "",
            homePhone: position.user.homePhone || "",
          };
          this.teamReport.push(positionReportRow);
        } catch (ex) {}
      });

      _.each(registration.roster, (roster) => {
        if (!roster || !roster.player) return;
        try {
          let rosterReportRow = {
            team: registration.team.name || "",
            agegroup: registration.ageGroup.name || "",
            level: registration.teamLevel.name || "",
            type: "player",
            firstName: roster.player.firstName || "",
            lastName: roster.player.lastName || "",
            email: roster.player.guardianemail || "",
            phone: roster.player.phoneNumber || "",
            homePhone: "",
          };
          this.teamReport.push(rosterReportRow);
        } catch (ex) {}
      });
    });
    this.buildCsv();
  }

  buildCsv() {
    let cols = [
      "team",
      "agegroup",
      "level",
      "type",
      "first name",
      "last name",
      "email",
      "cell",
      "home",
    ];
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += cols.join(",") + "\r\n";
    this.teamReport.forEach((row) => {
      let values = [];
      // tslint:disable-next-line:forin
      for (let index in row) {
        values.push(row[index]);
      }
      csvContent += values.join(",") + "\r\n";
    });
    this.csvContent = encodeURI(csvContent);
  }

  download() {
    if (!this.csvContent) return;
    let link = document.createElement("a");
    link.setAttribute("href", this.csvContent);
    link.setAttribute(
      "download",
      `export_${this.season.name}_${this.ageGroup && this.ageGroup.name}.csv`
    );
    document.body.appendChild(link);

    link.click();
  }
}
