import { IScheduleConfig } from "../models/IScheduleConfig";
import { IAgeGroup } from "../models/IAgeGroup";
import { ITeamLevel } from "../models/ITeamLevel";
import { ILeagueScope } from "../models/ILeagueScope";
import { IFilter } from "../models/IFilter";
import { IDivision } from "../models/IDivision";
import { ITeamRegistration } from "../models/ITeamRegistration";
import { IGame } from "../models/IGame";
import { SchedulingService } from "../services/SchedulingService";
import { IService } from "restangular";
import { StateService } from "@uirouter/angularjs";
import _ from "lodash";

export class ScheduleController {
  static $inject = [
    "$state",
    "$scope",
    "$q",
    "Restangular",
    "SchedulingService",
  ];

  ageGroups: IAgeGroup[];
  ageGroup: IAgeGroup;
  teamLevel: ITeamLevel;
  scheduleConfig: IScheduleConfig;

  constructor(
    private $state: StateService,
    private leagueScope: ILeagueScope,
    private $q: ng.IQService,
    private Restangular: IService,
    private schedulingService: SchedulingService
  ) {
    let me = this;
    me.leagueScope.$watch(
      function () {
        return me.leagueScope.league.currentSeason;
      },
      function (newValue, oldValue) {
        if (newValue) {
          me.refresh();
        }
      },
      true
    );
    me.scheduleConfig = {
      attempts: 0,
      maxAttempts: 500,
      gamesEach: 1,
      maxGames: 18,
      useDivisionTeams: false,
      useOutOfDivisionTeams: false,
      maxOutOfDivisionGames: 1,
      useOldAlgorithm: false,
    } as IScheduleConfig;
  }

  load(teamlevel: ITeamLevel) {
    let me = this;

    let teamRelation = {
      relation: "team",
      scope: {
        fields: ["id", "name"],
      },
    };
    let filter: IFilter = {
      fields: {
        name: true,
        id: true,
        teamId: true,
        ageGroupId: true,
        teamlevelId: true,
        divisionId: true,
      },
      include: [
        teamRelation,
        { homeGames: [{ "awayTeam": teamRelation }, { "homeTeam": teamRelation }] },
        { awayGames: [{ "awayTeam": teamRelation }, { "homeTeam": teamRelation }] },
      ],
    };
    let promises = [];
    _.each(teamlevel && teamlevel.divisions, function (division: IDivision) {
      division.__uistate = { selected: true };
      let svc = me.Restangular.one("divisions", division.id).all(
        "teamregistrations"
      );
      let promise = svc
        .getList<ITeamRegistration>({ filter })
        .then(function (registrations: ITeamRegistration[]) {
          division.teamregistrations = registrations;
        });
      promises.push(promise);
    });
  }

  runSchedule() {
    let me = this;
    let filteredDivisions = _.filter(me.teamLevel.divisions, (division) => {
      return division.__uistate.selected;
    });
    if (me.scheduleConfig.useOldAlgorithm) {
      me.schedulingService.buildSchedule(
        filteredDivisions,
        me.scheduleConfig
      );
    } else {
      me.schedulingService.buildScheduleNew(
        filteredDivisions,
        me.scheduleConfig
      );
    }
  }

  refresh() {
    let me = this;
    let filter: IFilter = {
      include: { teamlevels: "divisions" },
    };
    let svc = this.Restangular.one(
      "seasons",
      this.leagueScope.league.currentSeason.id
    ).all("agegroups");
    svc
      .getList<IAgeGroup>({ filter })
      .then(function (agegroups) {
        me.ageGroups = agegroups;
      });
  }

  removeGames(teamLevel: ITeamLevel) {
    let me = this;
    if (!confirm("are you sure you would like to remove all of these games?"))
      return false;
    me.Restangular.one("teamlevels", teamLevel.id)
      .customDELETE("games")
      .then(function (result) {
        me.load(teamLevel);
      });
  }

  scheduleDivisionGames(division: IDivision) {
    let me = this;
    let svc = me.Restangular.all("games");
    let games = [];
    _.each(division.teamregistrations, function (teamregistration) {
      let g = _.map(teamregistration.homeGames, function (game) {
        return {
          homeTeamId: game.homeTeam.id,
          awayTeamId: game.awayTeam.id,
          seasonId: me.leagueScope.league.currentSeason.id,
          divisionId: division.id,
          agegroupId: me.ageGroup.id,
          teamlevelId: me.teamLevel.id,
        } as IGame;
      });
      games = games.concat(g);
    });
    svc.customPOST(games, "batch").then(function (result) {
      // console.log(result);
    });
  }

  scheduleGames() {
    let me = this;
    let svc = me.Restangular.all("games");

    let games = [];
    let filteredDivisions = _.filter(me.teamLevel.divisions, (division) => {
      return division.__uistate.selected;
    });
    _.each(filteredDivisions, function (division) {
      _.each(division.teamregistrations, function (teamregistration) {
        let g = _.map(teamregistration.homeGames, function (game) {
          return {
            homeTeamId: game.homeTeam.id,
            awayTeamId: game.awayTeam.id,
            seasonId: me.leagueScope.league.currentSeason.id,
            divisionId: division.id,
            agegroupId: me.ageGroup.id,
            teamlevelId: me.teamLevel.id,
          } as IGame;
        });
        games = games.concat(g);
      });
    });
    svc.customPOST(games, "batch").then(function (result) {
      // console.log(result);
    });
  }
}
