import { IDateTime } from "../models/IDateTime";
import { IFacility } from "../models/IFacility";
import { IFilter } from "../models/IFilter";
import { IGame } from "../models/IGame";
import { Injectable } from "angular-ts-decorators";
import { IService } from "restangular";
import { ITournament } from "../models/ITournament";

@Injectable("GameEditorService")
export class GameEditorService {
  static $inject = ["Restangular", "$uibModal", "$q"];
  constructor(
    private Restangular: IService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $q: angular.IQService
  ) {}

  editLocation(game: IGame, facilities: IFacility[]): ng.IPromise<IGame> {
    let me = this;
    let promise = me.$q<IGame>(function(resolve, reject) {
      me.$uibModal
        .open({
          template: require("../templates/gameeditor/gameeditor.html").default,
          size: "lg",
          resolve: {
            game() {
              return game;
            },
            facilities() {
              return facilities;
            }
          },

          /* tslint:disable-next-line:no-shadowed-variable */
          controller: [
            "game",
            "facilities",
            function(game, facilities) {
              this.game = game;
              this.facilities = facilities;
              this.notTaken = function(date: IDateTime) {
                return !date.taken;
              };
            }
          ],
          controllerAs: "gameeditor"
        })
        .result.then(function(result: IDateTime) {
          (game.date
            ? me.Restangular.one("datetimes", game.date.id).customPATCH({
                datetime: result.datetime,
                facilityId: result.facility.id
              })
            : me.Restangular.all("datetimes")
                .customPOST({
                  datetime: result.datetime,
                  facilityId: result.facility.id
                })
                .then((newDate) => {
                  game.date = newDate;
                  return me.Restangular.one("games", game.id).customPATCH({
                    dateId: newDate.id
                  });
                })
          )
            .then(function(response) {
              game.date.datetime = result.datetime;
              game.date.facility = result.facility;
              resolve(game);
            })
            .catch(function(error) {
              reject(error);
            });
        });
    });
    return promise;
  }

  scoreGame(game: IGame): ng.IPromise<IGame> {
    let me = this;
    let promise = me.$q<IGame>(function(resolve, reject) {
      me.$uibModal
        .open({
          template: require("../templates/gameeditor/gamescore.html").default,
          size: "sm",
          resolve: {
            game() {
              return game;
            }
          },
          /* tslint:disable-next-line:no-shadowed-variable */
          controller: [
            "game",
            function(game) {
              this.game = game;
              this.homeTeamScore = game.homeTeamScore;
              this.awayTeamScore = game.awayTeamScore;
            }
          ],
          controllerAs: "gamescore"
        })
        .result.then(function(result: IGame) {
          me.Restangular.one("games", game.id)
            .customPATCH({
              homeTeamScore:
                result.homeTeamScore >= 0 ? result.homeTeamScore : null,
              awayTeamScore:
                result.awayTeamScore >= 0 ? result.awayTeamScore : null
            })
            .then(function(response) {
              game.homeTeamScore = result.homeTeamScore;
              game.awayTeamScore = result.awayTeamScore;
              resolve(game);
            })
            .catch(function(error) {
              reject(error);
            });
        });
    });
    return promise;
  }
}
