import { IGame } from "../../../models/IGame";
import { IUser } from "../../../models/IUser";
import { IRound } from "../../../models/IRound";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { ITeamRegistration } from "../../../models/ITeamRegistration";
import { ITournament } from "../../../models/ITournament";
import { SchedulingService } from "../../../services/SchedulingService";
import { StandingsService } from "../../../services/StandingsService";
import { TournamentsService } from "../../../services/TournamentsService";
import { IDateTime } from "../../../models/IDateTime";
import { IFilter } from "../../../models/IFilter";
import { IFacility } from "../../../models/IFacility";
import { IService } from "restangular";
import _ from "lodash";
import template from "./template.html";
import { Component, Input, Output } from "angular-ts-decorators";

@Component({
  selector: "singleElimination",
  template,
})
export class SingleElimination {
  static $inject = [
    "Restangular",
    "StandingsService",
    "SchedulingService",
    "TournamentsService",
    "$q",
  ];
  rounds: IRound[];
  @Input()
  tournament: ITournament;
  @Input()
  roleInfo: IRoleInfo;
  @Input()
  userInfo: IUser;
  @Input("@")
  leagueId: string;
  @Input()
  teams: ITeamRegistration[];
  @Input("@")
  runMode: string;
  @Output()
  onSaveComplete: () => {};
  facilities: IFacility[];
  numberOfTeams: number;
  matches: IGame[] = [];

  constructor(
    private Restangular: IService,
    private standingsService: StandingsService,
    private schedulingService: SchedulingService,
    private tournamentService: TournamentsService,
    private $q: ng.IQService
  ) {}

  loadFacilities() {
    let me = this;
    let facilityFilter: IFilter = {
      include: ["availability", "dates"],
    } as IFilter;
    me.Restangular.one("leagues", me.leagueId)
      .all("facilities")
      .getList<IFacility>({ filter: facilityFilter })
      .then(function (facilities) {
        me.facilities = facilities;
      });
  }

  saveGame(game: IGame, round: IRound, t: ITournament): ng.IPromise<any> {
    let me = this;
    let roundSvc = me.Restangular.one("rounds", round.id);
    if (game.date) {
      game.date.facilityId = game.date.facility.id;
      return me.Restangular.all("datetimes")
        .post({
          datetime: game.date.datetime,
          facilityId: game.date.facilityId,
          taken: game.taken,
        } as IDateTime)
        .then(function (addedDate) {
          let gamesSvc = roundSvc.all("games");
          return gamesSvc.post({
            game_number: game.game_number,
            homeTeamId: game.homeTeam && game.homeTeam.id,
            homeTeamPromise: game.homeTeamPromise,
            awayTeamId: game.awayTeam && game.awayTeam.id,
            awayTeamPromise: game.awayTeamPromise,
            seasonId: t.season.id,
            agegroupId: t.agegroup.id,
            teamlevelId: t.teamlevel.id,
            isTournamentMatch: true,
            dateId: addedDate.id,
          } as IGame);
        });
    } else {
      let gamesSvc = roundSvc.all("games");
      return gamesSvc.post({
        game_number: game.game_number,
        homeTeamId: game.homeTeam && game.homeTeam.id,
        homeTeamPromise: game.homeTeamPromise,
        awayTeamId: game.awayTeam && game.awayTeam.id,
        awayTeamPromise: game.awayTeamPromise,
        seasonId: t.season.id,
        agegroupId: t.agegroup.id,
        teamlevelId: t.teamlevel.id,
        isTournamentMatch: true,
      } as IGame);
    }
  }

  save(t: ITournament) {
    let me = this;
    let tournamentsSvc = me.Restangular.all("tournaments");
    let tournament: ITournament = {
      name: t.name,
      type: "singe_elimination",
      seasonId: t.season.id,
      agegroupId: t.agegroup.id,
      teamlevelId: t.teamlevel.id,
      gameTime: t.gameTime,
      restTime: t.restTime,
      startInterval: t.startInterval,
      includeChampionship: true,
    } as ITournament;

    tournamentsSvc
      .post(tournament)
      .then(function (tourney) {
        let tournamentSvc = me.Restangular.one("tournaments", tourney.id);
        let roundsSvc = tournamentSvc.all("championship");
        let roundsDefer = me.$q.defer();
        let roundsPromises = [];
        _.each(t.championship, function (round) {
          let newRound: IRound = {
            roundNumber: round.roundNumber,
          } as IRound;
          roundsPromises.push(
            roundsSvc.post<IRound>(newRound).then(function (savedRound) {
              let roundSvc = me.Restangular.one("rounds", savedRound.id);
              let gamesDefer = me.$q.defer();
              let gamesPromises = [];
              _.each(round.games, function (game) {
                gamesPromises.push(me.saveGame(game, savedRound, t));
              });
              me.$q.all(gamesPromises).then(function () {
                gamesDefer.resolve();
              });
              return gamesDefer.promise;
            })
          );
        });
        me.$q.all(roundsPromises).then(function () {
          roundsDefer.resolve();
        });
        return roundsDefer.promise;
      })
      .then(function () {
        me.onSaveComplete();
      });
  }

  onGameChange() {
    this.resolvePromises();
  }

  resolvePromises() {
    let me = this;
    me.tournamentService.resolvePromises(me.tournament);
  }

  sortData() {
    let me = this;
    me.tournament.championship = _.sortBy(
      me.tournament.championship,
      "roundNumber"
    );
    _.each(me.tournament.championship, function (round, roundIndex) {
      round.games = _.sortBy(round.games, "game_number");
    });
  }

  /**
   * Main logic to build the single eliminiation tournament schedule.
   * Probably refactor this out into a tournament service
   */
  buildSchedule() {
    let me = this;
    me.tournament.championship = me.schedulingService.buildBracket(
      me.numberOfTeams,
      me.tournament.participatingTeams
    );
  }
  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    if (this.tournament.participatingTeams) {
      this.numberOfTeams =
        this.numberOfTeams || this.tournament.participatingTeams.length;
      this.buildSchedule();
    }
    this.sortData();
    this.resolvePromises();
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {
    if (this.runMode === "create" || this.runMode === "edit") {
      this.loadFacilities();
    }
  }
}
