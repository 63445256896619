import { ILeagueScope } from '../models/ILeagueScope';
import { IGame } from '../models/IGame';
import { ITeamLevel } from '../models/ITeamLevel';
import { IDivision } from '../models/IDivision';
import { IService } from 'restangular';
import { IFacility } from '../models/IFacility';

export class GameEditorController {
    static $inject = ['$scope', 'Restangular', '$uibModalInstance', 'game', 'teamLevel', 'division', 'homeFields'];

    constructor(private $scope: ILeagueScope, private Restangular: IService, private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private game: IGame, private teamLevel: ITeamLevel, private division: IDivision, private homeFields: IFacility[]) {
        debugger;
    }

    rescheduleGame(game: IGame) {
        if (!confirm('are you sure you would like to reschedule this game, this is saved immediately?')) return;
        let me = this;
        me.Restangular.one('games', game.id).customPATCH({ dateId: game.dateId }, 'unsetdate')
            .then(function () {
                game.date.taken = false;
                game.date = null;
            });
    }

    setEditing(game: IGame, editing: boolean) {
        if (!game.__uistate) game.__uistate = {};
        game.__uistate.editing = editing;
    }

    editGameDate(game: IGame) {
        this.setEditing(game, true);
    }
}
