import { Component, Input, Output } from "angular-ts-decorators";
import { IGame } from "../../../models/IGame";
import { IRound } from "../../../models/IRound";
import { IFacility } from "../../../models/IFacility";
import { IRoleInfo } from "../../../models/IRoleInfo";
import { IUser } from "../../../models/IUser";
import { IDateTime } from "../../../models/IDateTime";
import { GameEditorService } from "../../../services/GameEditorService";
import { PermissionsService } from "../../../services/PermissionsService";
import { SchedulingService } from "../../../services/SchedulingService";
import _ from "lodash";
import { IService } from "restangular";
import template from "./template.html";

@Component({
  selector: "bracket",
  template,
})
export class Bracket {
  static $inject = [
    "Restangular",
    "$uibModal",
    "GameEditorService",
    "PermissionsService",
    "SchedulingService",
  ];
  @Input() rounds: any[];
  @Input() facilities: IFacility[];
  @Input() roleInfo: IRoleInfo;
  @Input() userInfo: IUser;
  @Input() runMode: string;
  @Input("@") leagueId: string;
  @Output() onChange: () => {};
  constructor(
    private Restangular: IService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private gameEditorService: GameEditorService,
    private permissionsService: PermissionsService,
    private schedulingService: SchedulingService
  ) {}

  roundClass(index: number) {
    return "round-" + (index + 1);
  }

  saveGame(game: IGame) {
    let me = this;
    me.Restangular.one("games", game.id)
      .customPATCH({
        homeTeamScore: game.homeTeamScore,
        awayTeamScore: game.awayTeamScore,
      })
      .then(function (result) {
        me.onChange();
      });
  }

  resolvePromises() {
    let sortedRounds = _.sortBy(this.rounds, "roundNumber");
  }

  editGame(game: IGame) {
    let me = this;
    me.gameEditorService.editLocation(game, me.facilities);
  }

  enterScore(game: IGame) {
    let me = this;
    me.gameEditorService.scoreGame(game).then(function (result) {
      me.onChange();
    });
  }

  canEnterScore(game: IGame) {
    let me = this;
    return (
      me.permissionsService.isScoreKeeper(
        me.roleInfo,
        game.awayTeam && game.awayTeam.team,
        me.userInfo
      ) ||
      me.permissionsService.isScoreKeeper(
        me.roleInfo,
        game.homeTeam && game.homeTeam.team,
        me.userInfo
      )
    );
  }

  determineSeeding() {
    let me = this;
    if (!me.rounds || me.rounds.length <= 0) return;
    let sortedGames = _.orderBy(me.rounds[0].games, "game_number");
    let n = me.rounds[0].games.length * 2;
    let r = Math.ceil(Math.log(n) / Math.log(2));
    let m = me.schedulingService.buildMatches(r, n);
    let allTeams = [];
    _.each(sortedGames, function (g: IGame, idx: number) {
      if (g.homeTeam) {
        g.homeTeam.seed = m[idx * 2];
        allTeams.push(g.homeTeam);
      }
      if (g.awayTeam) {
        g.awayTeam.seed = m[idx * 2 + 1];
        allTeams.push(g.awayTeam);
      }
    });

    _.each(me.rounds, function (round: IRound) {
      _.each(round.games, function (g: IGame) {
        if (g.homeTeam && !g.homeTeam.seed) {
          let sameTeam = _.find(allTeams, function (t) {
            return g.homeTeam.id === t.id;
          });
          if (sameTeam) {
            g.homeTeam.seed = sameTeam.seed;
          }
        }
        if (g.awayTeam && !g.awayTeam.seed) {
          let sameTeam = _.find(allTeams, function (t) {
            return g.awayTeam.id === t.id;
          });
          if (sameTeam) {
            g.awayTeam.seed = sameTeam.seed;
          }
        }
      });
    });
  }

  /**
   * event handler when any bound property changes
   */
  $onChanges(changesObj) {
    this.determineSeeding();
    // this.tournament.flights = [];
    // this.localTeams = _.clone(this.tournament.participatingTeams);
  }

  /**
   * event handler for when the component and all child components are inited
   */
  $onInit() {
    // this.tournament.flights = [];
    // this.localTeams = _.clone(this.tournament.participatingTeams);
  }
}
