import _ from 'lodash';
import { IAgeGroup } from '../models/IAgeGroup';
import { IAvailability } from '../models/IAvailability';
import { IDateTime } from '../models/IDateTime';
import { IDivision } from '../models/IDivision';
import { IFacility } from '../models/IFacility';
import { IFilter } from '../models/IFilter';
import { IFlight } from '../models/IFlight';
import { IGame } from '../models/IGame';
import { ILeagueScope } from '../models/ILeagueScope';
import { IRound } from '../models/IRound';
import { IService } from 'restangular';
import { ISortingRule, ISortingRules } from '../models/ISortingRules';
import { ITeamLevel } from '../models/ITeamLevel';
import { ITeamRegistration } from '../models/ITeamRegistration';
import { ITournament } from '../models/ITournament';
import { ITournamentType } from '../models/ITournamentType';
import { SortingAlgorithmFactory, SortingType } from '../services/SortingAlgorithmFactory';
import { StandingsService } from '../services/StandingsService';
import { StateService } from '@uirouter/angularjs';
import { TournamentsService } from '../services/TournamentsService';

export class TournamentAdminController {
    static $inject = ['$state', '$scope', 'Restangular', '$q', 'StandingsService', 'TournamentsService', 'SortingAlgorithmFactory'];
    tournamentType: ITournamentType;
    tournamentTypes: ITournamentType[] = [
        {
            type: 'singe_elimination',
            name: 'single elimination'
        },
        {
            type: 'flights_single_elimination',
            name: 'flights with single elimination'
        }
    ];
    allTeams: ITeamRegistration[];
    allTeamsOptions: any;
    newTournament: ITournament;
    sortingRules: ISortingRules;
    sortingOptions: ISortingRule[];
    sortingConfig: any;
    constructor(private $state: StateService, private leagueScope: ILeagueScope, private Restangular: IService, private $q: ng.IQService, private standingsService: StandingsService, private tournamentsService: TournamentsService, private sortingAlgorithmFactory: SortingAlgorithmFactory) {
        let me = this;
        me.newTournament = {
            flights: [],
            participatingTeams: []
        } as ITournament;
        me.leagueScope.$watch(
            function () { return me.leagueScope.league.currentSeason; },
            function (newValue, oldValue) {
                if (newValue) {
                    me.refresh();
                }
            },
            true
        );
        me.allTeamsOptions = {
            stop(e, ui) {
                me.getSelectedTeams();
            }
        };
        me.sortingConfig = {
            stop(e, ui) {
                me.calculateStandingsData();
            }
        };
        me.sortingRules = { rules: [] } as ISortingRules;
        me.sortingOptions = [
            { property: 'pwr', direction: 'desc', selected: false },
            { property: 'rpi', direction: 'desc', selected: false },
            { property: 'wins', direction: 'desc', selected: false },
            { property: 'losses', direction: 'asc', selected: false },
            { property: 'ties', direction: 'desc', selected: false },
            { property: 'hth', direction: 'desc', selected: false },
            { property: 'hth2o', direction: 'desc', selected: false },
            { property: 'pf', direction: 'desc', selected: false },
            { property: 'pa', direction: 'asc', selected: false },
            { property: 'paa', direction: 'asc', selected: false }
        ];
    }
    ageGroups: IAgeGroup[];
    tournaments: ITournament[];

    updateSortingOptions(option: ISortingRule) {
        let me = this;
        if (option.selected) {
            me.sortingRules.rules.push(option);
        } else {
            let idx: number = me.sortingRules.rules.indexOf(option);
            if (idx >= 0) me.sortingRules.rules.splice(idx, 1);
        }
        me.calculateStandingsData();
    }

    refresh() {
        let me = this;
        me.newTournament.season = this.leagueScope.league.currentSeason;
        let filter: IFilter = {
            include: { teamlevels: 'divisions' }
        };
        let svc = this.Restangular.one('seasons', this.leagueScope.league.currentSeason.id).all('agegroups');
        svc.getList<IAgeGroup>({ filter })
            .then(function (agegroups) {
                me.ageGroups = agegroups;
            });

        me.tournamentsService.getSeasonTournamnets(this.leagueScope.league.currentSeason.id)
            .then(function (tournaments) {
                me.tournaments = tournaments;
                if (me.$state.params.tournamentId) {
                    me.selectedTournamentIndex = _.findIndex(me.tournaments, function (tournament) {
                        return tournament.id === me.$state.params.tournamentId;
                    });
                    me.selectedTournament = me.tournaments[me.selectedTournamentIndex];
                }
            });
    }

    load(teamlevel: ITeamLevel) {
        let me = this;
        let filter: IFilter = {
            fields: { name: true, id: true, teamId: true, ageGroupId: true, teamlevelId: true, divisionId: true },
            include: [
                {
                    relation: 'team',
                    scope: {
                        fields: ['id', 'name'],
                    },
                },
                { homeGames: ['date', 'awayTeam', 'homeTeam'] },
                { awayGames: ['date', 'awayTeam', 'homeTeam'] }
            ]
        };
        let promises = [];
        _.each(teamlevel.divisions, function (division: IDivision) {
            let svc = me.Restangular.one('divisions', division.id).all('teamregistrations');
            let promise = svc.getList<ITeamRegistration>({ filter })
                .then(function (registrations: ITeamRegistration[]) {
                    division.teamregistrations = registrations;
                });
            promises.push(promise);
        });
        this.$q.all(promises).then(function () {
            me.allTeams = [];
            _.each(me.newTournament.teamlevel.divisions, function (d) {
                _.each(d.teamregistrations, function (tr: ITeamRegistration) {
                    tr.__uistate = tr.__uistate || {};
                    tr.__uistate.include = true;
                    me.allTeams.push(tr);
                });
            });
            me.calculateStandingsData();
        });

    }

    calculateStandingsData() {
        let me = this;
        me.allTeams = me.standingsService.calculateStandingsOfTeamRegistrations(me.allTeams, me.sortingRules);
        me.refreshStandings();
    }

    refreshStandings() {
        let me = this;
        me.allTeams = me.sortingAlgorithmFactory.createDyanmicSortingAlgorithm(me.sortingRules).sortStandings(me.allTeams);
        me.getSelectedTeams();
    }

    getSelectedTeams() {
        this.newTournament.participatingTeams = _.filter(this.allTeams, function (tr) {
            return tr.__uistate && tr.__uistate.include;
        });
    }

    selectedTournamentIndex: number;
    selectTournament(t: ITournament) {
        let me = this;
        me.selectedTournamentIndex = _.findIndex(me.tournaments, function (tourney) {
            return t.id === tourney.id;
        });
        // me.selectedTournament = t;
        me.$state.go('league.tournament.run', { tournamentId: me.selectedTournament.id });
    }

    selectedTournament: ITournament;
    edit() {
        if (this.selectedTournament) {
            this.$state.go('league.tournamentadmin.edit', { tournamentId: this.selectedTournament.id });
        }

    }

    deleteTournament(t: ITournament) {
        if (!confirm('are you sure you want to delete this tournament, this can not be undone?')) return false;
        let me = this;
        let promises = [];
        _.each(t.flights, function (flight: IFlight) {
            _.each(flight.rounds, function (round: IRound) {
                _.each(round.games, function (game: IGame) {
                    if (game.date && game.date.id) {
                        promises.push(me.Restangular.one('datetimes', game.date.id).remove());
                    }
                    promises.push(me.Restangular.one('rounds', round.id).one('games', game.id).remove());
                });
                promises.push(me.Restangular.one('rounds', round.id).customDELETE('games'));
            });
            _.each(flight.games, function (game: IGame) {
                if (game.date && game.date.id) {
                    promises.push(me.Restangular.one('datetimes', game.date.id).remove());
                }
                promises.push(me.Restangular.one('flights', flight.id).one('games', game.id).remove());
            });
            _.each(flight.teamregistrations, function (tr: ITeamRegistration) {
                promises.push(me.Restangular.one('flights', flight.id).all('teamregistrations').one('rel', tr.id).remove());
            });
            promises.push(me.Restangular.one('flights', flight.id).customDELETE('rounds'));
        });
        _.each(t.championship, function (round: IRound) {
            _.each(round.games, function (game: IGame) {
                if (game.date && game.date.id) {
                    promises.push(me.Restangular.one('datetimes', game.date.id).remove());
                }
                promises.push(me.Restangular.one('rounds', round.id).one('games', game.id).remove());
            });
        });
        promises.push(me.Restangular.one('tournaments', t.id).customDELETE('championship'));
        promises.push(me.Restangular.one('tournaments', t.id).customDELETE('flights'));
        promises.push(me.Restangular.one('tournaments', t.id).remove());
        me.$q.all(promises)
            .then(function () {
                let idx = me.tournaments.indexOf(t);
                if (idx >= 0) me.tournaments.splice(idx, 1);
                me.selectedTournament = null;
                me.$state.go('league.tournamentadmin');
                me.refresh();
            });
    }

    saveName(t: ITournament) {
        let me = this;
        me.Restangular.one('tournaments', t.id).customPATCH({ name: t.name })
            .then(function (result) {
                // do nothing
            });
    }

    saveComplete() {
        this.$state.go('league.tournamentadmin');
        this.refresh();
    }

    save(t: ITournament) {
        let me = this;
        let tournamentsSvc = me.Restangular.all('tournaments');
        let tournament: ITournament = {
            name: me.newTournament.name,
            type: 'flights_single_elimination',
            seasonId: me.leagueScope.league.currentSeason.id,
            agegroupId: me.newTournament.agegroup.id,
            teamlevelId: me.newTournament.teamlevel.id,
            includeChampionship: t.includeChampionship
        } as ITournament;
        tournamentsSvc.post(tournament)
            .then(function (savedTourney) {
                let tournamentSvc = me.Restangular.one('tournaments', savedTourney.id);
                _.each(t.flights, function (flight) {
                    let newFlight: IFlight = {
                        name: flight.name,
                        gamesEach: flight.gamesEach,
                        bracketTeams: flight.bracketTeams,
                        flight_number: flight.flight_number
                    } as IFlight;
                    tournamentSvc.all('flights').post(newFlight)
                        .then(function (savedflight) {
                            let flightSvc = me.Restangular.one('flights', savedflight.id);
                            let teamsSvc = flightSvc.all('teamregistrations');
                            _.each(flight.teamregistrations, function (team) {
                                teamsSvc.one('rel', team.id).customPUT({ flightId: savedflight.id, teamregistrationId: team.id });
                            });
                            _.each(flight.games, function (game: IGame) {
                                if (game.date) {
                                    game.date.facilityId = game.date.facility.id;
                                    me.Restangular.all('datetimes').post({
                                        datetime: game.date.datetime,
                                        facilityId: game.date.facilityId,
                                        taken: game.taken
                                    } as IDateTime)
                                        .then(function (addedDate) {
                                            flightSvc.all('games').post({
                                                game_number: game.game_number,
                                                homeTeamId: game.homeTeam.id,
                                                awayTeamId: game.awayTeam.id,
                                                seasonId: me.leagueScope.league.currentSeason.id,
                                                agegroupId: me.newTournament.agegroup.id,
                                                teamlevelId: me.newTournament.teamlevel.id,
                                                dateId: addedDate.id,
                                                isTournamentMatch: true
                                            } as IGame);
                                        });
                                } else {
                                    flightSvc.all('games').post({
                                        game_number: game.game_number,
                                        homeTeamId: game.homeTeam.id,
                                        awayTeamId: game.awayTeam.id,
                                        seasonId: me.leagueScope.league.currentSeason.id,
                                        agegroupId: me.newTournament.agegroup.id,
                                        teamlevelId: me.newTournament.teamlevel.id,
                                        isTournamentMatch: true
                                    } as IGame);
                                }

                            });
                            let roundsService = flightSvc.all('rounds');
                            _.each(flight.rounds, function (round) {
                                let newRound: IRound = {
                                    roundNumber: round.roundNumber
                                } as IRound;
                                roundsService.post<IRound>(newRound)
                                    .then(function (savedRound) {
                                        let roundSvc = me.Restangular.one('rounds', savedRound.id);
                                        _.each(round.games, function (game) {
                                            game.isTournamentMatch = true;
                                            if (game.date) {
                                                game.date.facilityId = game.date.facility.id;
                                                me.Restangular.all('datetimes').post({
                                                    datetime: game.date.datetime,
                                                    facilityId: game.date.facilityId,
                                                    taken: game.taken
                                                } as IDateTime)
                                                    .then(function (addedDate) {
                                                        let gamesSvc = roundSvc.all('games');
                                                        gamesSvc.post({
                                                            game_number: game.game_number,
                                                            homeTeamId: game.homeTeam && game.homeTeam.id,
                                                            homeTeamPromise: game.homeTeamPromise,
                                                            awayTeamId: game.awayTeam && game.awayTeam.id,
                                                            awayTeamPromise: game.awayTeamPromise,
                                                            seasonId: me.leagueScope.league.currentSeason.id,
                                                            agegroupId: me.newTournament.agegroup.id,
                                                            teamlevelId: me.newTournament.teamlevel.id,
                                                            isTournamentMatch: true,
                                                            dateId: addedDate.id
                                                        } as IGame);
                                                    });
                                            } else {
                                                let gamesSvc = roundSvc.all('games');
                                                gamesSvc.post({
                                                    game_number: game.game_number,
                                                    homeTeamId: game.homeTeam && game.homeTeam.id,
                                                    homeTeamPromise: game.homeTeamPromise,
                                                    awayTeamId: game.awayTeam && game.awayTeam.id,
                                                    awayTeamPromise: game.awayTeamPromise,
                                                    seasonId: me.leagueScope.league.currentSeason.id,
                                                    agegroupId: me.newTournament.agegroup.id,
                                                    teamlevelId: me.newTournament.teamlevel.id,
                                                    isTournamentMatch: true
                                                } as IGame);
                                            }
                                        });
                                    });
                            });
                        });
                });
                let roundsSvc = tournamentSvc.all('championship');
                _.each(t.championship, function (round) {
                    let newRound: IRound = {
                        roundNumber: round.roundNumber
                    } as IRound;
                    roundsSvc.post<IRound>(newRound)
                        .then(function (savedRound) {
                            let roundSvc = me.Restangular.one('rounds', savedRound.id);
                            _.each(round.games, function (game) {

                                if (game.date) {
                                    game.date.facilityId = game.date.facility.id;
                                    me.Restangular.all('datetimes').post({
                                        datetime: game.date.datetime,
                                        facilityId: game.date.facilityId,
                                        taken: game.taken
                                    } as IDateTime)
                                        .then(function (addedDate) {
                                            let gamesSvc = roundSvc.all('games');
                                            gamesSvc.post({
                                                game_number: game.game_number,
                                                homeTeamId: game.homeTeam && game.homeTeam.id,
                                                homeTeamPromise: game.homeTeamPromise,
                                                awayTeamId: game.awayTeam && game.awayTeam.id,
                                                awayTeamPromise: game.awayTeamPromise,
                                                seasonId: me.leagueScope.league.currentSeason.id,
                                                agegroupId: me.newTournament.agegroup.id,
                                                teamlevelId: me.newTournament.teamlevel.id,
                                                isTournamentMatch: true,
                                                dateId: addedDate.id
                                            } as IGame);
                                        });
                                } else {
                                    let gamesSvc = roundSvc.all('games');
                                    gamesSvc.post({
                                        game_number: game.game_number,
                                        homeTeamId: game.homeTeam && game.homeTeam.id,
                                        homeTeamPromise: game.homeTeamPromise,
                                        awayTeamId: game.awayTeam && game.awayTeam.id,
                                        awayTeamPromise: game.awayTeamPromise,
                                        seasonId: me.leagueScope.league.currentSeason.id,
                                        agegroupId: me.newTournament.agegroup.id,
                                        teamlevelId: me.newTournament.teamlevel.id,
                                        isTournamentMatch: true
                                    } as IGame);
                                }

                            });
                        });
                });
            });
    }

}
